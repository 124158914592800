import React, {useState, useRef, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import './App.css';
import AlternatingSections from "./components/AlternatingSections/AlternatingSections";
import Footer from "./components/Footer/Footer";
import RevShare from "./components/RevShare/RevShare";

function App() {
    const [ init, setInit ] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);


    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

  const scrollToSection = () => {
    const section = document.getElementById("alternating-sections");
    if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
  };

    function toggleMenu() {
      setMenuOpen(!menuOpen);
    }

    const particlesLoaded = (container) => {
        console.log(container);
    };

  return (
      <Router>
    <div className="app">
        { init && <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={{
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 50,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 1,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.2,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 3 },
                    },
                },
                detectRetina: true,
            }}
        />
}
        <Routes>
            <Route exact path="/" element={
                <>
                <div style={{"position": "relative"}}>
      <header>
        <nav>
            <div className="wrapper">
                <img src="/logo-img.png" alt="CRYPTON" className="logo" />
                <button className="burger-menu" onClick={toggleMenu}>
                    {menuOpen ? (
                        <span className="cross">&#10005;</span>  // Render cross when menu is open
                    ) : (
                        <>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </>
                    )}
                </button>
                <ul className={`nav-list ${menuOpen ? 'show' : ''}`}>
                    <li>
                        <a href="https://x.com/thetonhub" className="nav-list-link">Twitter</a>
                    </li>
                    <li>
                        <a href="https://t.me/cryptonportal" className="nav-list-link">Telegram</a>
                    </li>
                    <li>
                        <a href="https://docs.crypton.tools" className="nav-list-link">Docs</a>
                    </li>
                    <li>
                        <a href="https://app.ston.fi/swap?chartVisible=true&ft=TON&chartInterval=1w&tt=EQD36Lxp6p4FMzHQThWzvFNaqhbT8Qip4rMF1NYjCSgY6ksE" className="button button-primary">Buy $CRYPTON</a>
                    </li>
                </ul>
                <div className={`overlay ${menuOpen ? 'show' : ''}`}></div>
            </div>
        </nav>
      </header>
        <ul className={`nav-list-mobile ${menuOpen ? 'show' : ''}`}>
            <li>
                <a href="https://x.com/thetonhub" className="nav-list-link">Twitter</a>
            </li>
            <li>
                <a href="https://t.me/cryptonportal" className="nav-list-link">Telegram</a>
            </li>
            <li>
                <a href="https://docs.crypton.tools" className="nav-list-link">Docs</a>
            </li>
            <li>
                <a href="https://app.ston.fi/swap?chartVisible=true&ft=TON&chartInterval=1w&tt=EQD36Lxp6p4FMzHQThWzvFNaqhbT8Qip4rMF1NYjCSgY6ksE" className="button button-primary">Buy $CRYPTON</a>
            </li>
        </ul>
      <section className="intro">
        <h1 className="title-desktop">Enjoy cutting-edge utilities tailored by<br/> CRYPTON specifically for the TON blockchain.</h1>
        <h1 className="title-mobile">Enjoy cutting-edge utilities tailored by CRYPTON specifically for the TON blockchain.</h1>
          <div className="intro-buttons">
            <a href="https://t.me/cryptonportal" className="button button-secondary">Join The Hub &rarr;</a>
            <a href="https://staking.crypton.tools" className="button button-secondary">Stake $CRYPTON &rarr;</a>
            <a href="https://staking.crypton.tools" className="button button-secondary">Revenue Leaderboard &rarr;</a>
          </div>
      </section></div>
        <div style={{"width": "100%", "background-color": "#000000"}}>

        <section className="revshare wrapper">
            <h1>Revenue Share</h1>
              <p>Earn simply by holding $CRYPTON. TON will be airdropped to wallets automatically.</p>
              <p>Revenue generated since September 17th:</p>
              <RevShare />
          </section>
        <AlternatingSections />
            <section className="revshare" style={{"position": "relative", "backgroundColor": "#000", "paddingTop": "150px", "zIndex": "5"}}>
                <div className="wrapper">
                    <h1 style={{"textAlign": "center"}}>Revenue Share & Subscription Program</h1>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
                        <b>60 % of the AD revenue</b> generated from CryptonDeploys, CryptonBuybot, TonTrending, CryptoniteScanner and Wallet tracker will be shared directly with the stakers in Ton.
</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
<b>60% of the trading fees</b> generated from the SuperBot (sniper/trading) will also be shared back to the stakers.
</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px", "fontStyle": "bold"}}>
                        <b>Rewards Formula for each individual stake:</b>
</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
REVENUE_FOR_STAKE = TOTAL_REVENUE * (STAKE_POINTS / TOTAL_STAKES_POINTS)
        </p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
STAKE_POINTS = STAKE_DURATION^2 * (STAKE_AGE_IN_DAYS / TOTAL_STAKE_PERIOD_IN_DAYS) * NUMBER_OF_STAKED_TOKENS * MULTIPLIER
</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
STAKE_DURATION^2 - duration in months, squared (for 1 month it is 1, for 3 months - 9, for 6 months - 36)
            </p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
STAKE_AGE_IN_DAYS - how many days ago you staked
            </p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
TOTAL_STAKE_PERIOD_IN_DAYS - total number of days of your stake (30, 90 or 180)
                  </p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
NUMBER_OF_STAKED_TOKENS - how much you staked (for example - 1000 $CRYPTON)

</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px", "fontStyle": "bold"}}>
<b>MULTIPLIER for Large Stakes</b>
</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
— <b>Gold Tier (0.1%-0.99% staked)</b> - 2x the amount of "points" for this stake
</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
                        — <b>Diamond Tier (1%-1.99% staked)</b> - 3x the amount of "points" for this stake
</p>
                    <p style={{"textAlign": "center", "margin": "20px auto 0", "maxWidth": "800px"}}>
— <b>Emerald Tier (2% and more staked)</b> - 4x the amount of "points" for this stake

</p>
                </div>
            </section>
        <Footer />
            </div>

        </>} />
        </Routes>
    </div>
      </Router>
  );
}

export default App;
